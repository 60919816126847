<template>
  <router-view :currentServer="currentServer" @changeToLive="changeToLive" @changeToStaging="changeToStaging"
    @changeToLiveStaging="changeToLiveStaging" />
</template>

<script>
// import {
//   VUE_APP_API_BASE_URL_STAGING,
//   VUE_CLIENT_SECRET_STAGING,
//   VUE_CLIENT_ID_STAGING,
//   VUE_APP_API_BASE_URL_PRODUCTION,
//   VUE_CLIENT_SECRET_PRODUCTION,
//   VUE_CLIENT_ID_PRODUCTION,
//   VUE_APP_API_BASE_URL_LIVESTAGING,
//   VUE_CLIENT_ID_LIVESTAGING,
//   VUE_CLIENT_SECRET_LIVESTAGING
// } from './variables';
export default {
  data() {
    return {
      currentServer: localStorage.getItem('serverType') === 'livestaging' ? {
        baseUrl: process.env.VUE_APP_API_BASE_URL_LIVESTAGING,
        clientId: process.env.VUE_APP_API_CLIENT_ID_LIVESTAGING,
        clientSecret: process.env.VUE_APP_API_CLIENT_SECRET_LIVESTAGING,
        environment: '/live-staging/'
      } : localStorage.getItem('serverType') === 'staging' ? {
        baseUrl: process.env.VUE_APP_API_BASE_URL_STAGING,
        clientId: process.env.VUE_APP_API_CLIENT_ID_STAGING,
        clientSecret: process.env.VUE_APP_API_CLIENT_SECRET_STAGING,
        environment: '/staging/'
      } : localStorage.getItem('serverType') === 'local' ? {
        baseUrl: process.env.VUE_APP_API_BASE_URL_LOCAL,
        clientId: process.env.VUE_APP_API_CLIENT_ID_LOCAL,
        clientSecret: process.env.VUE_APP_API_CLIENT_SECRET_LOCAL,
        environment: '/local/'
      } : {
        baseUrl: process.env.VUE_APP_API_BASE_URL_PRODUCTION,
        clientId: process.env.VUE_APP_API_CLIENT_ID_PRODUCTION,
        clientSecret: process.env.VUE_APP_API_CLIENT_SECRET_PRODUCTION,
        environment: '/'
      }
    }
  },
  methods: {
    changeToLive() {

      this.currentServer = {
        baseUrl: process.env.VUE_APP_API_BASE_URL_PRODUCTION,
        clientId: process.env.VUE_APP_API_CLIENT_ID_PRODUCTION,
        clientSecret: process.env.VUE_APP_API_CLIENT_SECRET_PRODUCTION,
        environment: '/'
      }

      localStorage.setItem('serverType', 'live')
      this.$router.push('/admin-login')
    },

    changeToStaging() {
      this.currentServer = {
        baseUrl: process.env.VUE_APP_API_BASE_URL_STAGING,
        clientId: process.env.VUE_APP_API_CLIENT_ID_STAGING,
        clientSecret: process.env.VUE_APP_API_CLIENT_SECRET_STAGING,
        environment: '/staging/'
      }

      localStorage.setItem('serverType', 'staging')
      this.$router.push('/admin-login')
    },

    changeToLiveStaging() {
      this.currentServer = {
        baseUrl: process.env.VUE_APP_API_BASE_URL_LIVESTAGING,
        clientId: process.env.VUE_APP_API_CLIENT_ID_LIVESTAGINGID_LIVESTAGING,
        clientSecret: process.env.VUE_APP_API_CLIENT_SECRET_LIVESTAGING,
        environment: '/live-staging/'
      };
      localStorage.setItem('serverType', 'livestaging');
      this.$router.push('/admin-login');
    },

    changeToLocal() {
      this.currentServer = {
        baseUrl: process.env.VUE_APP_API_BASE_URL_LOCAL,
        clientId: process.env.VUE_APP_API_CLIENT_ID_LOCAL,
        clientSecret: process.env.VUE_APP_API_CLIENT_SECRET_LOCAL,
        environment: '/local'
      };
      localStorage.setItem('serverType', 'local');
      this.$router.push('/admin-login');
    },
  }
};
</script>